/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

// jQuery
import $ from 'jquery';
import './modernizr-custom.js';

// ResponsiveBP
import 'responsive-bp/src/js/responsive.core.js';
import 'responsive-bp/src/js/responsive.navigation.js';
import 'responsive-bp/src/js/responsive.dropdown.js';


// Headroom
import Headroom from 'headroom.js';

// Wrap element with Swiper elements
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="round icon-only border button -green swiper-button-prev"><i class="icon icon-arrow-left"></i></a>');
		$element.append('<a class="round icon-only border button -green swiper-button-next"><i class="icon icon-arrow-right"></i></a>');
	}
};
var hoverSwiper = function (element, swiper) {
	var $element = $(element);

	var autoplayRunning = false;
	$element.hover(function () {
		autoplayRunning = swiper.autoplay.running;
		swiper.autoplay.stop();
	}, function () {
		if (autoplayRunning) {
			swiper.autoplay.start();
		}
	});

	if (swiper.params.autoplay.disableOnInteraction) {
		swiper.on('slideChange', function () {
			autoplayRunning = false;
		});
	}
};

$(async function () {
//	// Open sub menu on touch or in responsive menu
	$('header .sub').each(function () {
		var element = $(this),
			a = element.children('a');

		a.click(function (e) {
			if (!element.hasClass('open')) {
				element.addClass('open');
				e.preventDefault();
			}
		});
	});

//	// Object fit fallback
	if (!Modernizr.objectfit) {
		$('.object-fit picture').each(function () {
			var element = $(this);
			var image = element.find('img');
			var parent = element.parent();

			parent.css({ 'background-size': 'cover', 'background-image': 'url(' + image.attr('src') + ')', 'background-position': 'center center' });
			element.remove();
		});
	}

//	// Slider experiences
	$('.slider-experiences').each(async function () {
		const Swiper = (await import('swiper')).default;
		var $this = $(this);

		wrapSwiper($this, '.slide', false, true);

		var swiper = new Swiper(this, {
			speed: 1000,
			spaceBetween: 50, // Grid column width
			slidesPerView: 1,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			autoplay: true
		});

		hoverSwiper($this, swiper);
	});

	// Slider experiences
	$('.slider-topics').each(async function () {
		const Swiper = (await import('swiper')).default;
		var $this = $(this);

		wrapSwiper($this, '.slide', false, true);

		// Remove block row classes
		var blockClasses = $.map(['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'], function (bp) {
			return $.map([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12], function (cols) {
				return 'block-row-' + bp + '-' + cols;
			}).join(' ');
		}).join(' ');
		$this.removeClass(blockClasses);

		var swiper = new Swiper(this, {
			speed: 1000,
			spaceBetween: 0,
			slidesPerView: 1,
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			breakpointsInverse: true,
			breakpoints: {
				480: {
					slidesPerView: 2
				},
				640: {
					slidesPerView: 3
				},
				468: {
					slidesPerView: 4
				},
				992: {
					slidesPerView: 5
				},
				1280: {
					slidesPerView: 6
				}
			},
			autoplay: true
		});

		hoverSwiper($this, swiper);
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import('lightgallery');
		await import('lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false
		});
	});

//	// Headroom back-to-top
	$('.back-to-top').each(function () {
		var height = $('footer').outerHeight(),
			windowHeight = $(window).outerHeight(),
			documentHeight = $(document).outerHeight();

		var headroom = new Headroom(this, {
			offset: documentHeight - windowHeight - height
		}).init();
	});


	// Change target for external sites
	var getDomainName = function (hostName) {
		return hostName.substring(hostName.lastIndexOf('.', hostName.lastIndexOf('.') - 1) + 1);
	};
	$('a:not([target])').filter(function () {
		var link = $(this);

		if (link.filter('[rel~="external"]').length) return true; // Link rel attribute contains 'external'

		var href = link.attr('href');
		if (new RegExp('\.pdf$', 'i').test(href)) return true; // Link to PDF file

		if (new RegExp('^https?://', 'i').test(href)) {
			var linkHostName = getDomainName(href.substring(href.indexOf('/') + 2, href.indexOf('/', 8)));
			if (!new RegExp('(^|\.)' + getDomainName(document.location.hostname) + '$', 'i').test(linkHostName)) {
				// Link is to different domain name
				return true;
			}
		}

		return false;
	}).attr('target', '_blank').attr('rel', 'noopener');
});